import React from 'react'
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export default function CreditCard(
  data: any,
  setConfirmSelect: any
) {

  return (
    
      <Card
        key={data.marca + data.vence}
        onClick={() => setConfirmSelect({ modal: true, card: data })}
        sx={
          [
            {
              display: "flex",
              placeContent: "space-between",
              background: "linear-gradient(269.77deg, #1C7C92 0.03%, rgba(98, 119, 162, 0) 136.59%)"
            },
            data.active !== 'disabled' && { borderRadius: 1, borderWidth: 3, borderStyle: 'solid', borderColor: '#c749da' }
          ]}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography component="div" variant="h5">
              {data.marca}
            </Typography>
            <Typography component="div" variant="h5"  color="text.secondary" style={{
                fontFamily: "Courier New",
                fontSize: 14,
                 fontWeight: 700,
              }}>
              {data.type === "debit"? "Débito" : "Crédito"}
            </Typography>
            <Typography
              style={{
                fontFamily: "Courier New",
                fontStyle: "normal",
                fontWeight: 700,
                letterSpacing: "-0.01em",
              }}
              color="text.secondary"
              component="div"
            >
              {data.name}
            </Typography>
          </CardContent>
        </Box>
        <Box sx={{ display: "flex" }}>
          <CardContent sx={{
            display: "flex", flexDirection: "column", float: "right",
            placeContent: "space-around"
          }}>
            <Typography
              component="div"
              variant="h5"
              style={{
                textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                fontFamily: "Courier New",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: 14,
                letterSpacing: "-0.01em",
                textAlign: "right",
              }}
            >
              Vence {data.vence}
            </Typography>
            <Typography
              component="div"
              variant="h5"
              style={{
                textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                fontFamily: "Courier New",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: 14,
                letterSpacing: "-0.01em",
                textAlign: "right",
              }}
            >
              ****{"  "} {data.last4}
            </Typography>
          </CardContent>
        </Box>
      </Card>
    
  );
}
