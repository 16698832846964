/**
 * Este js se encarga de la gestión del estado propio de las vistas signin y signup
 **/
import { createSlice } from "@reduxjs/toolkit";

/**
 * CreateSlice es un metodo que recibe un objeto de tres atributos
 * name - es el nombre de la objeto del store
 * initialState - es el estado con el que se inicia el objeto al levantar la applicacion
 * reducers - combina el action y el cambio de state de ese objeto cuando se usa
 */
export const sessionSlice = createSlice({
  name: "session",
  initialState: {
    token: null,
    authenticated: false,
    userLogged: null,
    currentPosition: null,
    newUser: false,
    emailDetected: "",
    userDummy: {
      terms_conditions_agreement: true,
      afip_responsability_type_id: 5,
      identification_type: 'DNI',
      address: "Tienda Web",
      state_city: "CABA",
      country_state: "Buenos Aires",
      country: "Argentina",
      description: "Tienda Web",
      mobile: "0012345678",
      avatar: null,
    },
  },
  reducers: {
    setUserEmailForLogin: (state, { payload }) => {
      state.emailDetected = payload.emailDetected;
      state.newUser = payload.newUser;
    },
    setUserSession: (state, action) => {
      state.token = true;
      state.authenticated = true;
      state.newUser = false;
      state.userLogged = action.payload;
    },
    cleanUserSession: (state) => {
      state.token = null;
      state.authenticated = false;
      state.userLogged = null;
    },
    setPosition: (state, action) => {
      state.currentPosition = action.payload;
    },
    setUserUpdateSession: (state, action) => {
      state.userLogged = action.payload;
    },
    setUserUpdateDummySession: (state, action) => {
      state.userDummy = action.payload;
    },
    resetUserSessionDummy: (state) => {
      state.userDummy = {
        terms_conditions_agreement: true,
        afip_responsability_type_id: 5,
        identification_type: 0,
        address: "Tienda Web",
        state_city: "CABA",
        country_state: "Buenos Aires",
        country: "Argentina",
        description: "Tienda Web",
        mobile: "0012345678",
        avatar: null,
      };
    },
  },
});

/**
 * Por un lado se exportan los actions asociados al reducer
 * por el otro se exporta el reducer en si mismo
 */
export const {
  setUserSession,
  cleanUserSession,
  setPosition,
  setUserUpdateSession,
  setUserEmailForLogin,
  setUserUpdateDummySession,
  resetUserSessionDummy,
} = sessionSlice.actions;
export default sessionSlice.reducer;
