import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { postAccountRecovery } from "../services/public.service";
import useFetchAndLoad from "../hooks/useFetchAndLoad";
import { ShStyle } from "../styles";



export default function ForgotPassword() {
  const dispatch = useDispatch()
  const navigation = useNavigate()
  const [byEmail, setByEmail] = useState(true)

  const { user } = useSelector((state: any) => state.auth)
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState<any>()
  const [formState, setFormState] = useState<any>({
    login: '', identification_type: 3, vat: ""
  })

  const validateData = () => {
    if (formState.login.length) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (user) { navigation('/select_card') }
  }, [])

  useEffect(() => {
    if (response?.error) {
      alert('Surgió un error inesperado')
    } else {
      if (response?.result?.status === "400") {
        alert('El usuario informado no existe')
      } else if (response?.result?.status === "200") {
        alert('Hemos enviado un correo al mail informado para recuperar su contraseña. Ante cualquier inconveniente contactarse con nuestro sector de soporte.')
        navigation('/')
      }
    }

    return () => {
      setFormState({ login: '', identification_type: 3, vat: "" })
    }
  }, [response])


  const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ login: '', identification_type: 3, vat: "" })
    setFormState((prev: any) => ({ ...prev, [name]: value }))
  }

  const recoverPass = async () => {
    await setLoading(true)
    if (validateData()) {
      await postAccountRecovery(formState)
        .then(res => {
          setResponse(res)
          setLoading(false)
        })
    } else {
      alert('Debe indicar un email válido para solicitar el recupero de contraseña')
      setLoading(false)
    }
  }

  return (
    <div style={stl.content}>
      <Stack style={ShStyle.center}>
        <img src="/logo.png" alt="" width={182} />
        <Typography variant="h5" color="white" align="center">
          Recuperar contraseña
        </Typography>
      </Stack>

      <Stack spacing={3}>

        <TextField
          onChange={handleChange}
          label="Email"
          name="login"
          type="email"
          variant="standard"
          fullWidth
          focused
        />




        <div style={stl.link}>
          {loading ?
            <Button
              onClick={() => { }}
              variant="outlined"
              fullWidth>
              ...
            </Button> :
            <Stack spacing={2}>
              <Button
                onClick={recoverPass}
                variant="contained"
                fullWidth>
                Enviar
              </Button>
            </Stack>}
        </div>
      </Stack >


    </div >
  );
}


const stl: any = {
  content: {
    display: "flex",
    height: "100vh",
    flexDirection: "column",
    margin: 18,
    justifyContent: "space-evenly",
  },
  link: {
    textDecoration: "none",
    boxShadow: "none",
  },
}
