import React from "react";
import { ButtonMG } from "../CommonComponents";
import { ButtonProp } from "../typings";

export const ButtonMGPrimary = ({ title, action, disabled, foco = false, variant = 'contained' }: ButtonProp) => {
  return (
    <ButtonMG
      autoFocus={foco}
      onClick={action}
      fullWidth
      variant={variant}
      sx={{ mt: 3, mb: 2 }}
      disabled={disabled}
    >
      {title}
    </ButtonMG>
  );
};
