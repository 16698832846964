import { createSlice } from '@reduxjs/toolkit'

const initialStateClean = {
    payments: [],
}

export const paymentsSlice = createSlice({
    name: 'payments',
    initialState: initialStateClean as any,
    reducers: {
        setPayments: (state, { payload }) => {
            state.payments = payload
        },
        cleanPaymentState: (state) => {
            state.payments = [];
        },
    },
})

export const { setPayments, cleanPaymentState } = paymentsSlice.actions
