import React from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";

import Home from "./views/Home";
import Cart from "./views/Cart";
import Entrance from "./views/Entrance";
import Password from "./views/Password";
import PaymentNew from "./views/PaymentNew";
import LoginAccount from "./views/LoginAccount";
import ForgotPassword from "./views/ForgotPassword";
import PurchaseSummary from "./views/PurchaseSummary";
import { SelectCard } from "./views/SelectCard";
import { RetryPayment } from "./views/RetryPayment";
import { MessagesControl } from "./views/MessagesControl";

import "./App.css";
import "animate.css";

function App() {
  const { userLogged } = useSelector((state: any) => state.session);

  try {
    window.screen.orientation.lock("portrait");
  } catch (error) { }

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      {userLogged ? (
        <>
          <Route path="select_card" element={<SelectCard />} />
          <Route path="new-card" element={<PaymentNew />} />
          <Route path="entrance" element={<Entrance />} />
          <Route path="verify-entrance" element={<MessagesControl />} />
          <Route path="cart" element={<Cart />} />
          <Route path="retry-payment" element={<RetryPayment />} />
          <Route path="summary" element={<PurchaseSummary />} />
        </>
      ) : (
        <>
          <Route path="login_account" element={<LoginAccount />} />
          <Route path="password" element={<Password />} />
          <Route path="recover-password" element={<ForgotPassword />} />
          <Route path="new-card" element={<PaymentNew />} />
        </>
      )}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default App;
