import { ApiCore } from "../utils/axios";
import { loadAbort } from "../utils/load-abort-axios.utility";

export const verifyUserExist = (email: string) => {
    const controller = loadAbort()
    return { call: ApiCore.get(`/users?login=${email}`, { signal: controller.signal }), controller }
};

export const loginEmailPassword = (login: string, password: string) => {
    const controller = loadAbort()
    return {
        call: ApiCore.post('/users/login', { login, password }, { signal: controller.signal }), controller
    }
};

export const getAccessStatus = async (login: string, store_id: number) => {
    try {
        const res = await ApiCore.post('/users/get_transaction', {
            login,
            store_id,
        });

        return res.data;
    } catch (error) {
        return false;
    }
};

/**
* Informa la consfirmación de acceso o no a la puerta
* @param {*} login
* @param {*} store_id
* @param {*} door_id
* @param {*} was_confirmed
* @returns  result => getAccessStatus:  {"id": null, "jsonrpc": "2.0", "result": [{"code": "7", "from_app": "access_control", "id": 1552, "login": "ec@go.com", "message": "sales order was created successfully"}]}
*/
export const accessStore = async (login: string, store_id: number, door_id: number, was_confirmed: boolean) => {
    try {
        // const res = { "id": null, "jsonrpc": "2.0", "result": [{ "code": "7", "from_app": "access_control", "id": 1552, "login": "ec@go.com", "message": "sales order was created successfully" }] }
        const res = await ApiCore.post('/users/send_message_access_control', {
            login,
            store_id,
            door_id,
            was_confirmed,
        });

        return res;
    } catch (error) {
        return false;
    }
};

/**
 * POST RECUPERAR CONTRASEÑA
 {
  "login": "z10@go.com",
  "identification_type": 3,
  "vat": "30111111117"
}
 */
export const postAccountRecovery = async (userData: any) => {
    try {
        const response = await ApiCore.post(`/users/ResetPassword`, userData);

        return response.data;
    } catch (error) {
        return console.error(error);
    }
}