import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IconButton, Stack, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { LogoHome } from "../components/LogoHome";
import { CardType } from "./SelectCard";
import { ShStyle } from "../styles";
import {
  enterStore,
  getOrderActive,
  getUserPaymentMethods,
  searchPaymentActive,
} from "../utils";
import { setEnterGroup } from "../store/slices";

export default function Entrance() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { userLogged } = useSelector((state: any) => state.session);
  const [loading, setLoading] = useState(true);
  const usersAccess = [
    { quantity: 0, title: "Ingreso solo yo" },
    { quantity: 1, title: "Ingreso con acompañantes" },
  ];

  useEffect(() => {
    if (!userLogged) {
      navigation("/login");
    } else {
      getCards();
      evaluationCart();
    }
  }, [userLogged]);

  // Si hay pedido en curso ir al carrito
  const evaluationCart = async () => {
    const res = await getOrderActive(userLogged.login, dispatch);
    if (res.length) {
      setLoading(false);
      navigation("/cart");
    }
    setLoading(false);
  };

  // Si no tiene tarjetas ir a crear una
  const getCards = async () => {
    let auxArr: CardType[] = [];

    await getUserPaymentMethods(userLogged?.login).then((data) => {
      if (data) {
        auxArr = data.map((el: any) => {
          return {
            id: el[0],
            marca: el[7],
            name: el[1],
            vence: `${el[4]}/${el[5]}`,
            last4: el[2].slice(el[2].length - 4),
            active: el[8],
          };
        });

        if (!searchPaymentActive(auxArr)) {
          navigation("/new-card");
        }
      } else {
        navigation("/new-card");
      }
    });
  };

  const selectPersons = async (onBoarding: number) => {
    await setLoading(true);

    if (onBoarding) {
      dispatch(setEnterGroup(true));
    } else {
      dispatch(setEnterGroup(false));
    }

    await enterStore(userLogged.login, "9", "1", "in")
      .then((response: any) => {
        if (response) {
          navigation("/verify-entrance", { state: { res: response.result } });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  if (loading) {
    return <LogoHome />;
  }

  return (
    <div style={ShStyle.content}>
      <Stack spacing={2} style={stl.stack}>
        <img src="/logo.png" alt="" width={100} />
        <Typography color="white" variant="h6">
          Ingreso a la tienda
        </Typography>
        <Typography color="white" variant="body1">
          Recordá que podes ingresar con hasta 2 acompañantes.
        </Typography>

        {renderUsersEntrance(usersAccess, selectPersons)}
      </Stack>
    </div>
  );
}

const renderUsersEntrance = (arrEntrance: any, selectPersons: any) => {
  return (
    <>
      {arrEntrance.map((el: any) => (
        <div
          key={el.quantity}
          onClick={() => selectPersons(el.quantity)}
          style={stl.usersAccess}
        >
          <IconButton aria-label="delete">
            <PersonIcon style={stl.iconPerson} />
          </IconButton>
          <Typography align="center" color="white" variant="h6">
            {el.title}
          </Typography>
        </div>
      ))}
    </>
  );
};

const stl: any = {
  usersAccess: {
    padding: 3,
    borderRadius: "10px",
    borderColor: "white",
    borderWidth: 1,
    borderStyle: "dashed",
    width: "90%",
  },
  iconPerson: { fontSize: 99, zIndex: 0, textAlign: "center", align: "center" },
  stack: { alignItems: "center", textAlign: "center" },
};
