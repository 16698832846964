import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL
const tokenAxios = process.env.REACT_APP_TOKEN || ''

const ApiCore = axios.create({ baseURL: API_URL });
ApiCore.defaults.headers.common["Content-Type"] = "application/json";

const AuthCore = axios.create({ baseURL: API_URL });
ApiCore.defaults.headers.common["Content-Type"] = "application/json";

AuthCore.interceptors.request.use(
  async (config: any) => {
    config.headers.authorization = `${tokenAxios}`;
    return config;
  },
  (error) => Promise.reject(error)
);

AuthCore.interceptors.response.use(
  async (res) => {
    return res;
  },
  (error) => Promise.reject(error)
);

export { ApiCore, AuthCore, tokenAxios, API_URL };
