import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { LayoutContainer } from "../CommonComponents";

export default function SpinnerLoader() {
  return (
    <LayoutContainer>
      <Box sx={{ display: "flex", alignSelf: "center" }}>
        <CircularProgress />
      </Box>
    </LayoutContainer>
  );
}
