import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { LogoHome } from "../components/LogoHome";

export default function Home() {
  const { userLogged } = useSelector((state: any) => state.session);
  const navigation = useNavigate();
  const [fade, setFade] = useState("animate__fadeIn");

  useEffect(() => {
    setTimeout(() => {
      setFade("animate__fadeOut");
    }, 1500);
    setTimeout(() => {
      navigation(userLogged ? "/select_card" : "/login_account");
    }, 2500);
  }, []);

  return <LogoHome fade={fade} />;
}
