import { ApiCore, AuthCore } from "./axios";
import {
  DELETE_USER_PAYMENT,
  GET_USER_CURRENT_ORDER,
  GET_USER_EXIST,
  PATCH_USER_NEW_PAYMENT,
  POST_ENTER_STORE,
  POST_LEAVE_OR_RETRY_PAYMENT,
  POST_LOGIN,
  POST_USER_NEW_PAYMENT,
  POST_USER_PAYMENTS,
} from "./endpoints";
import { errorExist } from "./errors";
import {
  cleanCart,
  setProductsInCart,
  setSummaryInCart,
} from "../store/slices";

var aux: any = {
  result: false,
  title: "Error inesperado",
  message: "Por favor, intente nuevamente en unos minutos",
};

const verifyUserExist = async (email: string) => {
  try {
    const res = await ApiCore.get(GET_USER_EXIST, {
      params: {
        login: email,
        vat: "",
        identification_type: "DNI",
      },
    });

    if (res.data.messsage === "User dont exists!") {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    console.error(error);
  }
};

const loginEmailPassword = async (login: string, password: string) => {
  try {
    const res = await ApiCore.post(POST_LOGIN, { login, password });
    let err = errorExist(res.data.result.messsage);

    if (!err) {
      return { result: true, data: res.data.result.data };
    } else {
      return {
        result: false,
        title: err.title,
        message: err.response || res.data.result.messsage,
      };
    }
  } catch (error) {
    return aux;
  }
};

const registrarUsuario = async (userData: any) => {
  try {
    const res = await ApiCore.post("/users", userData);
    let err: any = errorExist(res.data.result.message);

    if (!err && res.data.result.status !== "400") {
      aux = { result: true };
    } else {
      aux = {
        result: false,
        title: err.title || "Error inesperado",
        message: err.response || res.data.result.message,
      };
    }
    return aux;
  } catch (error) {
    return aux;
  }
};

// TODO: Backend corregir respuestas tuplas
const getUserPaymentMethods = async (login: string): Promise<any> => {
  try {
    const res: any = await AuthCore.post(POST_USER_PAYMENTS, { login });
    let arr = res.data?.result.data || [];

    if (parseInt(res.status) === 200) {
      return arr;
    } else {
      return res.messsage;
    }
  } catch (error) {
    console.log(error);
  }
};

const setUserPaymentMethod = async (method: any) => {
  try {
    const response: any = await AuthCore.post(POST_USER_NEW_PAYMENT, {
      ...method,
    }).catch(() => { return false; });

    if (response?.data?.result?.status === "201") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
};

const setDefaultPaymentMethod = async (card: any) => {
  try {
    const response = await AuthCore.post(PATCH_USER_NEW_PAYMENT, {
      ...card,
    }).catch(() => {
      return false;
    });

    return response;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Obtain active method to inactive (disbled)
 * get inactive method to active
 * @param {*} activeMethod
 * @param {*} inactiveMethod
 */
const updateDefaultPayment = async (activeMethod: any, inactiveMethod: any) => {
  if (activeMethod) {
    await setDefaultPaymentMethod({ ...activeMethod, state: "disabled" });
  }
  await setDefaultPaymentMethod({ ...inactiveMethod, state: "active" });
};

/**
 * Search the first and should be the unique payment active of an array
 * if exist return the payment.
 * If not, return false.
 */
const searchPaymentActive = (arrayPayments: any) => {
  let result = false;
  result = arrayPayments.find((el: any) => el.active === "active");
  return result;
};

const deletePaymentMethod = async (login: any, id: any) => {
  const res = await AuthCore.post(DELETE_USER_PAYMENT, {
    login,
    id,
  }).catch((err: any) => console.log(err));
  return res;
};

const getDefaultPaymentMethod = async (login: any) => {
  try {
    const res = await getUserPaymentMethods(login).then((res) =>
      searchPaymentActive(res)
    );

    return res;
  } catch (error) {
    return [];
  }
};

/**
 * Escanea QR
 * obtiene y parsea data
 * usa este enpoint para postear enterStore
 * @param {*} login
 * @param {*} store_id
 * @param {*} door_id
 * @param {*} type
 * @returns res.data =>  {"id": null, "jsonrpc": "2.0", "result": "{\"code\": 0, \"msg\": \"OK\", \"role\": \"customer\"}"}
 */
const enterStore = async (
  login: any,
  store_id: any,
  door_id: any,
  type: any
) => {
  try {
    const res = await ApiCore.post(POST_ENTER_STORE, {
      login,
      store_id,
      door_id,
      type,
    });

    return res.data;
    //return  {"id": null, "jsonrpc": "2.0", "result": "{\"code\": 0, \"msg\": \"OK\", \"role\": \"customer\"}"}
  } catch (error) {
    return false;
  }
};

const getOrderActive = async (login: string, dispatch: any) => {
  try {
    const res = await ApiCore.get(GET_USER_CURRENT_ORDER, {
      params: { login },
    });

    if (res.status === 200 || res.status === 400) {
      if (
        res.data.message ===
        "Usuario ec@go.com no posee ninguna orden de venta."
      ) {

        dispatch(cleanCart());
        return [];
      } else {
        const [summary, ...rest] = res.data;

        if (summary[0].sale_order_type[0] === 1) {
          dispatch(setProductsInCart(rest));
          dispatch(setSummaryInCart(summary[0]));
        } else {
          dispatch(cleanCart());
        }

        return res.data;
      }
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const postLeaveRetryOrder = async (order: any) => {
  try {
    await ApiCore.post(POST_LEAVE_OR_RETRY_PAYMENT, order);
  } catch (error) {
    console.error(error);
  }
};

const getTransactionLast = async (login: string) => {
  try {
    const res = await ApiCore.get(`/users/get_transaction/last?login=${login}`);

    return res.data;
  } catch (error) { }
};

export {
  getTransactionLast,
  postLeaveRetryOrder,
  getOrderActive,
  getDefaultPaymentMethod,
  enterStore,
  verifyUserExist,
  loginEmailPassword,
  getUserPaymentMethods,
  searchPaymentActive,
  setUserPaymentMethod,
  setDefaultPaymentMethod,
  updateDefaultPayment,
  deletePaymentMethod,
  registrarUsuario,
};
