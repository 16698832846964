const errors = [
  // Validate user
  {
    error: 'Incorrect user or password!',
    title: 'Datos incorrectos',
    response: 'Verifique usuario y contraseña y vuelva a intentarlo',
  },
  {
    error: 'Incorrect user or password!',
    title: 'Datos incorrectos',
    response: 'Verifique usuario y contraseña y vuelva a intentarlo',
  },
  {
    error: 'User does not exist!',
    title: 'Usuario Inexistente',
    response: 'Por favor, verifique la información ingresada',
  },
  {
    error: 'Email and Document already exists!',
    title: 'Error de datos',
    response: 'El email y/o el documento ingresados ya existen',
  },
  {
    error: 'Email User already exists!',
    title: 'Error de validación',
    response: 'El email ingresado ya se encuentra registrado',
  },
  {
    error: 'There is already a registered user with this Document!',
    title: 'Documento inválido',
    response: 'El documento ingresado ya se encuentra registrado',
  },
  // UserForm
  {
    error: 'There is already a registered user with this Document!',
    title: 'Documento inválido',
    response: 'El documento ingresado ya se encuentra registrado',
  },
  {
    error: 'name',
    title: 'Verificar nombre',
    response: 'Debe indicar al menos un nombre',
  },
  {
    error: 'surname',
    title: 'Verificar apellido',
    response: 'Debe indicar al menos un apellido',
  },
  {
    error: 'identification_type',
    title: 'Verificar Tipo de documento',
    response: 'Ingrese su tipo de Documento para continuar',
  },
  {
    error: 'vat',
    title: 'Verificar documento',
    response: 'Debe completar su número de documento',
  },
  {
    error: 'login',
    title: 'Verificar Email',
    response: 'Debe completar su email correctamente',
  },
  {
    error: 'emailConfirmation',
    title: 'Verificar confirmación de email',
    response: 'Debe completar la confirmación de su email',
  },
  {
    error: 'password',
    title: 'Verificar contraseña',
    response: 'Debe ingresar una constraseña de al menos 8 caracteres.',
  },
  {
    error: 'terms',
    title: 'Información importante',
    response: 'Para continuar debe aceptar los términos y condiciones.',
  },
];

export const errorExist = (errorEvaluate: string) => {
  let errorSearch = errors.find(el => el.error === errorEvaluate);
  if (errorSearch) return errorSearch;
  return false;
};
