import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { authSlice, paymentsSlice, cartSlice } from "./slices";
import { persistStore, persistReducer } from "redux-persist";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  deliveryMethodSlice,
  ecommerceSlice,
  firstLaunchSlice,
  sessionSlice,
  variablesSlice,
} from "./actions";

const reducers = combineReducers({
  auth: authSlice.reducer,
  payments: paymentsSlice.reducer,
  cart: cartSlice.reducer,

  session: sessionSlice.reducer,
  firstLaunch: firstLaunchSlice.reducer,
  variables: variablesSlice.reducer,
  delivery: deliveryMethodSlice.reducer,
  ecommerce: ecommerceSlice.reducer,
});

const persistConfig = {
  key: "root",
  storage: AsyncStorage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
