import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import styled from "@emotion/styled";

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  width: 90vw;
  max-width: 400px;
`;

export const LayoutBox = styled(Box)`
  flex: 1;
`;

export const ButtonMG = styled(Button)`
  font-weight: bold;
  color: #fff;
`;
