import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Typography } from "@mui/material";

import { createPaymentCard } from "../utils/rest";
import { ButtonsStep } from "../components/buttons";
import { Card, FormCard, SpinnerLoader } from "../components";
import { cleanUserSession, setLoading, setUserSession } from "../store/actions";
import { loginEmailPassword, registrarUsuario } from "../utils";
import { LayoutBox, LayoutContainer } from "../components/CommonComponents";
import { creditCardType } from "../utils/validateCard";

const PaymentNew = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { emailDetected, userDummy, newUser } = useSelector(
    (state: any) => state.session
  );
  const { loading } = useSelector((state: any) => state.variables);
  const [invalidState, setInvalidState] = useState(true);
  const [formStep, setFormStep] = useState(0);
  const [proveedor, setProveedor] = useState("CARD");
  const [values, setValues] = useState({
    name: '',
    card_number: '',
    security_code: '',
    expirationDate: '',
    card_type: '',
    card_identification: '',
    identificationNumber: ''
  });

  const createUserAndPayment = async () => {
    setInvalidState(true)

    if (newUser) {
      const result = await registerUser();
      result && await createNewPaymentCard()
      return
    } else {
      await createNewPaymentCard()
    }
    await setInvalidState(false)
  };

  const registerUser = async () => {
    await dispatch(cleanUserSession());

    const res = await registrarUsuario({
      ...userDummy,
      name: values.name,
      vat: values.identificationNumber,
      identification_type: 'DNI',
    })

    if (res.result) {
      await login()
      return true
    } else {
      toast(res.title + ", " + res.message);
      return false
    }
  };

  const login = async () => {
    const res: any = await loginEmailPassword(
      emailDetected.toLowerCase(),
      userDummy.password
    )
    await dispatch(setUserSession(res.data));
  }

  const createNewPaymentCard = async () => {
    await dispatch(setLoading(true));

    const { card_identification } = creditCardType(
      values.card_number,
      values.card_type,
    );

    const method = {
      login: emailDetected.toLowerCase(),
      name: values.name,
      // CARD
      card_number: values.card_number,
      security_code: values.security_code,
      expiration_month: values.expirationDate.slice(0, 2),
      expiration_year: "20" + values.expirationDate.slice(-2),
      card_type: values.card_type === 'Crédito' ? 'credit' : 'debit',
      card_identification,
      state: "disabled",
    };
console.log(method, "method")
    try {
      await createPaymentCard(method)
        .then((res) => {
          if (res) {
            navigate("/select_card");
          } else {
            toast("No se añadió la tarjeta, Por favor, verifique que todos los datos estén completos correctamente.");
          }
        });
    } catch (error) {
      toast("No se añadió la tarjeta, Por favor, verifique que todos los datos estén completos correctamente.");
    }
    await dispatch(setLoading(false));
  };

  const nextStep = useCallback(() => {
    setFormStep(formStep + 1);
  }, [formStep]);

  const previousStep = useCallback(() => {
    setFormStep(0);
  }, []);

  const handleChange = (prop: string) => (event: any) => {

    if (prop === "security_code") {
      if (!isNaN(event.target.value)) {
        setValues({ ...values, [prop]: event.target.value });
      }
    }
    else if (prop === "expirationDate") {
      if (/^[0-9/]*$/.test(event.target.value)) {
        setValues({ ...values, [prop]: event.target.value.replace("/", "") });
      }
    }
    else {
      setValues({ ...values, [prop]: event.target.value });
    }
  };
  if (loading) {
    return <div style={stl.content}>
      <SpinnerLoader />
    </div>
  }
  return (
    <LayoutContainer>
      <LayoutBox style={{ marginTop: 50 }}>
        <Typography
          style={{ textAlign: "center" }}
          component="h1"
          variant="subtitle1"
        >
          Ingrese{" "}
          {formStep === 0
            ? "Tipo y Número de tarjeta"
            : formStep === 1
              ? "el Titular"
              : formStep === 2
                ? "el Vencimiento y Código de Seguridad"
                : "el Número de Documento"}
        </Typography>
        <Card values={values} proveedor={proveedor} step={formStep} />
      </LayoutBox >

      <LayoutBox style={stl.box}>
        <FormCard
          {...{
            values,
            setValues,
            formStep,
            handleChange,
            setInvalidState,
            setProveedor,
          }}
        />

      </LayoutBox>
      <ButtonsStep
        {...{
          nextStep,
          previousStep,
          formStep,
          invalidState,
          createUserAndPayment,
        }}
      />
    </LayoutContainer >
  );
};

const stl: any = {
  content: {
    display: "flex",
    flexDirection: "column",
    margin: 18,
    // justifyContent: "space-evenly",
  },
  cardBtn: {
    textDecoration: "none",
    boxShadow: "none",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "start",
    marginBottom: 30,
  }
};

export default PaymentNew;
