import React from "react";
import { NumberCard } from "./card/NumberCard";
import { Owner } from "./card/Owner";
import { Expiration } from "./card/Expiration";
import { DocumentNumber } from "./card/DocumentNumber";

export const FormCard = ({
  formStep,
  handleChange,
  values,
  setValues,
  setInvalidState,
  setProveedor,
}: any) => {
  const renderFormStep = () => {
    switch (formStep) {
      case 0:
        return (
          <NumberCard
            handleChange={handleChange}
            values={values}
            setValues={setValues}
            setInvalidState={setInvalidState}
            setProveedor={setProveedor}
          />
        );

      case 1:
        return (
          <Owner
            handleChange={handleChange}
            values={values}
            setInvalidState={setInvalidState}
          />
        );

      case 2:
        return (
          <Expiration
            handleChange={handleChange}
            values={values}
            setInvalidState={setInvalidState}
          />
        );

      case 3:
        return (
          <DocumentNumber
            handleChange={handleChange}
            values={values}
            setInvalidState={setInvalidState}
          />
        );
    }
  };

  return <>{renderFormStep()}</>;
};
