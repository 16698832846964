import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";

import { LogoComponent, SpinnerLoader } from "../components";
import { ButtonMGPrimary } from "../components/buttons";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import logo from "../assets/images/minigo_logo.png";
import { useDispatch, useSelector } from "react-redux";
import { loginEmailPassword, validation } from "../utils";

import { toast } from "react-toastify";
import { TermsLink } from "../components/TermsLink";
import {
  cleanUserSession,
  setLoading,
  setUserEmailForLogin,
  setUserSession,
  setUserUpdateDummySession,
} from "../store/actions";
import { LayoutBox, LayoutContainer } from "../components/CommonComponents";

const Password = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state: any) => state.variables);
  const { newUser, emailDetected, userDummy } = useSelector(
    (state: any) => state.session
  );

  const [values, setValues] = useState({
    password: "",
    typePass: false,
  });

  const handleChange = (prop: string) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      typePass: !values.typePass,
    });
  };

  const signIn = async () => {
    if (!validation("email", emailDetected) || !values.password.length) {
      return;
    }

    try {
      dispatch(setLoading(true));
      const res: any = await loginEmailPassword(
        emailDetected.toLowerCase(),
        values.password
      );

      if (res.result && res.data) {
        await dispatch(setUserSession(res.data));
        await navigate("/select_card");
      } else {
        toast(
          res.title || "Error de autenticación",
          res.message || "No se logró autenticar con las credenciales"
        );
      }
      dispatch(setLoading(false));
    } catch (error) {
      console.error(error);
      toast(
        "Error de autenticación - No se logró autenticar con las credenciales"
      );
    }
  };

  const signUp = async () => {
    if (!validation("email", emailDetected) || !validation('password', values.password)) {
      toast("Verifique el formato de su usuario y contraseña")
      return;
    }
    try {
      await dispatch(setLoading(true));
      await dispatch(cleanUserSession());
      await dispatch(
        setUserUpdateDummySession({
          ...userDummy,
          login: emailDetected,
          password: values.password,
          terms_conditions_agreement: true,
        })
      );
      await dispatch(setLoading(false));
      await navigate("/new-card");
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (!emailDetected) {
      navigate("/");
    }
  }, [emailDetected, navigate]);

  return loading ? (
    <div style={stl.content}>
      <SpinnerLoader />
    </div>
  ) : (
    <div style={stl.content}>
      <LayoutContainer>
        <LayoutBox>
          <LogoComponent image={logo} />
          <div
            style={{
              display: "flex",
              flex: 1,
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              {newUser
                ? "Tu primera gran experiencia"
                : "Gracias por volver a visitarnos"}
            </Typography>
          </div>

          <Typography component="h1" variant="body2">
            Email: {emailDetected}
          </Typography>

          <Box component="form" noValidate sx={{ mt: 1 }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                autoFocus
                id="outlined-adornment-password"
                type={values.typePass ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                inputProps={{ maxLength: 100 }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {values.typePass ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <Typography component="h1" variant="body2">
              Alfanumérica y mínimo 8 caracteres. Ej: GOgo12$$
            </Typography>

            <Typography component="p"   style={{
                  marginTop: 8
                }}>
              <Link
                style={{
                  color: "#3DDAD0",
                  textDecoration: "none",
                 
                }}
                to="/recover-password"
              >
                ¿Olvidaste la contraseña?
              </Link>
            </Typography>

            {newUser ? (
              <ButtonMGPrimary title="Registrarme" action={signUp} />
            ) : (
              <ButtonMGPrimary title="Ingresar" action={signIn} />
            )}
            <ButtonMGPrimary
              variant="outlined"
              title="Usar otro email"
              action={() =>
                dispatch(
                  setUserEmailForLogin({
                    emailDetected: "",
                    newUser: "",
                  })
                )
              }
            />

            <TermsLink />
          </Box>
        </LayoutBox>
      </LayoutContainer>
    </div>
  );
};

const stl: any = {
  content: {
    display: "flex",
    flexDirection: "column",
    margin: 18,
    justifyContent: "space-evenly",
  },
  cardBtn: {
    textDecoration: "none",
    boxShadow: "none",
  },
};

export default Password;
