import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { creditCardType } from "../../utils/validateCard";
const validDataCardNumber = require('card-validator');

export const NumberCard = ({
  handleChange,
  values,
  setInvalidState,
  setProveedor,
}: any) => {
  const [alertCardNotSupported, setAlertCardNotSupported] = useState({ providerNotSuported: false, provider: '' })
const[prov, setProv]= useState(false)

  useEffect(() => {
    const { provider } = creditCardType(values.card_number.replace(/\s+/g, ""), values.card_type,);
    setProveedor(provider)
    provider === "amex"?  setProv(true): setProv(false)
   /*  if (provider === 'amex') {
      setAlertCardNotSupported({ providerNotSuported: true, provider })
    } else {
      setAlertCardNotSupported({ providerNotSuported: false, provider: '' })
    } */
   setInvalidState(!(validDataCardNumber.number(values.card_number).isValid && values.card_type && provider !== 'otros'/*  && provider !== 'amex' */))
  
    return () => setInvalidState(true)
  }, [values.card_number, values.card_type])

  

  function cc_format(value: string) {
    const v = value
      .replace(/\s+/g, "")
      .replace(/[^0-9]/gi, "")
    const parts = [];
  
    for (let i = 0; i < v.length; i += 4) {
      parts.push(v.slice(i,i+ 4));
    }
  
    return parts.length > 1 ? parts.join(" ") : value;
  }


  return (
    <Box component="form" noValidate sx={{ mt: 5 }}>
      {alertCardNotSupported.providerNotSuported ?
        <Typography
          style={{ textAlign: "center" }}
          component="h1"
          variant="subtitle1"
          color="error"
        >
          {alertCardNotSupported.provider.length ? `Tarjeta ${alertCardNotSupported.provider.toUpperCase()} No Soportada` : `Tarjeta No Soportada`}
        </Typography> : null}
      <FormControl fullWidth>
        <InputLabel id="card_type">Tipo de Tarjeta</InputLabel>
        <Select
          labelId="card_type"
          id="card_type"
          value={values.card_type}
          label="Tipo de Tarjeta"
          onChange={handleChange("card_type")}
        >
          <MenuItem style={{ color: "white" }} value={'Crédito'}>
            Crédito
          </MenuItem>
          <MenuItem style={{ color: "white" }} value={'Débito'}>
            Débito
          </MenuItem>
        </Select>
      </FormControl>

      <TextField
        autoComplete="off"
        autoCorrect="off"
        margin="normal"
        required
        fullWidth
        id="card_number"
        label='N° de la tarjeta'
        name="card_number"
        inputProps={{ inputMode: "numeric", pattern: "[0-9]*",   maxLength: prov? 18:19}}
        //type="number"
        value={cc_format(values.card_number) }
        //value={values.card_number}
        onChange={handleChange("card_number")}
        error={!validDataCardNumber.number(values.card_number).isValid}
      />
    </Box>
  );
};
