import React from "react";
import { Stack, Typography } from "@mui/material";

export const LogoHome = ({ fade = "animate__fadeIn" }) => {

    return (
        <div className={`animate__animated ${fade}`} style={stl.container}>
            <Stack spacing={1} style={{ alignItems: "center" }}>
                <img src="/logo.png" alt="" width={182} />
                <Typography variant="h5" color="white" align="center">
                    ¡Bienvenidos a MiniGO!
                </Typography>
            </Stack>
        </div>
    )
}


const stl: any = {
    container: {
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        margin: 18,
        justifyContent: "space-evenly",
    }
}