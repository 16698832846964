import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AddCardRoundedIcon from "@mui/icons-material/AddCardRounded";
import { Stack, Typography, Button } from "@mui/material";
import { CreditCard, MessageModal } from "../components";
import {
  searchPaymentActive,
  updateDefaultPayment,
  deletePaymentMethod,
} from "../utils/userService";
import { useAsync, useFetchAndLoad } from "../hooks";
import { getUserPaymentMethods } from "../services/private.service";
import { ShStyle } from "../styles";
import { cleanUserSession } from "../store/actions";

export type CardType = {
  id: any;
  marca: any;
  name: any;
  vence: string;
  last4: any;
  active: string;
};

export const initialStateCard = {
  id: "",
  marca: "",
  name: "",
  vence: "",
  last4: "",
  active: "",
  type: ""
};

export const SelectCard = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { userLogged } = useSelector((state: any) => state.session);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [tarjetas, setTarjetas] = useState<CardType[]>([]);
  const [confirmSelect, setConfirmSelect] = useState({
    modal: false,
    card: initialStateCard,
  });

  const getApiGetCards = async () =>
    await callEndpoint(getUserPaymentMethods(userLogged?.login));
  const logout = () => dispatch(cleanUserSession());

  const getCards = async (data: any) => {
    let auxArr: CardType[] = [];

    if (data.result.data) {
      auxArr = data.result.data.map((el: any) => {
        return {
          id: el[0],
          marca: el[7],
          name: el[1],
          vence: `${el[4]}/${el[5]}`,
          last4: el[2].slice(el[2].length - 4),
          active: el[8],
          type: el[6]
        };
      });
      setTarjetas(auxArr);
    } else {
      setTarjetas([]);
    }
  };

  useEffect(() => {
    if (!userLogged) {
      navigation("/login_account");
    }
  }, [userLogged, navigation]);

  useAsync(getApiGetCards, getCards, () => { },
    [confirmSelect.modal]
  );

  const setMethodDefault = async () => {
    const res: any = await searchPaymentActive(tarjetas);

    if (res) {
      await updateDefaultPayment(
        { id: res.id, login: userLogged.login }, // method active
        { id: confirmSelect?.card.id, login: userLogged.login } // method disabled
      );
    } else {
      await updateDefaultPayment(
        {},
        { id: confirmSelect?.card.id, login: userLogged.login } // method disabled
      );
    }
    await setConfirmSelect({ modal: false, card: initialStateCard });
  };

  const deletePayment = async () => {
    await deletePaymentMethod(userLogged.login, confirmSelect?.card.id).then(
      (res: any) => {
        if (res) {
          getApiGetCards().then((data) => getCards(data));
          setConfirmSelect({ modal: false, card: initialStateCard });
        }
      }
    );
  };

  if (!userLogged) {
    return null;
  }

  if (loading) {
    return (
      <div style={stl.content}>
        <Stack style={{ alignItems: "center" }}>
          <img src="/logo.png" alt="" width={182} />
          <Typography variant="h5" color="white" align="center">
            ...
          </Typography>
        </Stack>
      </div>
    );
  }

  return (
    <div style={stl.content}>
      <Stack style={ShStyle.center}>
        <img src="/logo.png" alt="" width={182} />
        <Typography variant="h5" color="white" align="center">
          Bienvenid@
          <br />
          {userLogged?.name || "Usuari@"}
        </Typography>
      </Stack>

      <Stack spacing={2}>
        {!tarjetas.length ? (
          <Typography variant="h6" color="white" align="center">
            Parece que no hay tarjetas
          </Typography>
        ) : (
          tarjetas.map((x) => CreditCard(x, setConfirmSelect))
        )}

        <Link to="/new-card" style={stl.cardBtn}>
          <Button variant="contained" fullWidth>
            Adicionar Tarjeta
          </Button>
        </Link>
        {tarjetas.length ? (
          <Link to="/entrance" style={stl.cardBtn}>
            <Button variant="contained" fullWidth>
              IR A COMPRAR
            </Button>
          </Link>
        ) : null}
        <div onClick={logout} style={stl.cardBtn}>
          <Button variant="outlined" fullWidth>
            CERRAR SESIÓN
          </Button>
        </div>
      </Stack>

      <MessageModal
        type="text"
        Icon={AddCardRoundedIcon}
        title="¿Cambiar medio de pago?"
        text={["Estas por seleccionar tu medio de pago predeterminado"]}
        actions={[
          { title: "Seleccionar", onClick: setMethodDefault },
          { title: "Eliminar", onClick: deletePayment },
        ]}
        handleClose={() => { }}
        open={confirmSelect.modal}
      />
    </div>
  );
};

const stl: any = {
  content: {
    display: "flex",
    flexDirection: "column",
    margin: 18,
    justifyContent: "space-evenly",
  },
  cardBtn: {
    textDecoration: "none",
    boxShadow: "none",
  },
};
