const validDataCardNumber = require('card-validator');

export const completeValidState = dataCard => {
  // VALIDO SI EXISTE TIPO DE TARJETA
  if (!dataCard.card_type.length > 0) {
    return {
      state: false,
      msg: {
        title: 'Campo inválido',
        description: 'Debe indicar un tipo de tarjeta',
      },
    };
  }

  // VALIDO SI EXISTE NOMBRE DEL TITULAR
  if (!validDataCardNumber.cardholderName(dataCard.name).isValid) {
    return {
      state: false,
      msg: {
        title: 'Campo inválido',
        description:
          'Debe indicar el nombre del titular como figura en la tarjeta',
      },
    };
  }

  // VALIDA EL NUMERO DE TARJETA
  if (!validDataCardNumber.number(dataCard.card_number).isValid) {
    return {
      state: false,
      msg: {
        title: 'Campo inválido',
        description:
          'Verifique que el número de tarjeta ingresado sea correcto y numérico',
      },
    };
  }

  // VALIDA LA FECHA DE VENCIMIENTO
  if (
    !(
      validDataCardNumber.expirationMonth(dataCard.expiration_month).isValid &&
      validDataCardNumber.expirationYear(dataCard.expiration_year).isValid &&
      (dataCard.expiration_month + dataCard.expiration_year.slice(-2))
        .length === 4
    )
  ) {
    return {
      state: false,
      msg: {
        title: 'Campo inválido',
        description:
          'Verifique la fecha de vencimiento. Formato MMAA y que sea superior al año actual',
      },
    };
  }

  // VALIDO CODIGO DE SEGURIDAD LARGO DE 3
  if (dataCard.security_code.length !== 3) {
    return {
      state: false,
      msg: {
        title: 'Campo inválido',
        description: 'Verifique que el código de seguridad sea numérico',
      },
    };
  }

  return {
    state: true,
    msg: { title: '', description: '' },
  };
};


export const creditCardType = (numberCreditCard, cardType) => {
  // card_identification:
  // 1 Visa
  // 30 Visa Débito
  // 29 MasterCard
  // 31 MasterCard Débito
  // 32 Maestro
  // 90 Amex Credito
  // 89 Cabal Debito

  let amex = new RegExp('^3[47][0-9]{13}$');
  let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
  let mastercard = new RegExp('^5[1-5][0-9]{14}$');
  let mastercard2 = new RegExp('^2[2-7][0-9]{14}$');
  let maestro = new RegExp(
    '^(5018|5020|5038|5893|6304|6759|6761|6762|6763)[0-9]{8,19}$',
  );
  let cabal = new RegExp('(60420[1-9]|6042[1-9][0-9]|6043[0-9]{2}|604400)');

  if (visa.test(numberCreditCard.replace(/\s+/g, ""))) {
    if (cardType === 'Crédito') {
      return { card_identification: '1', provider: 'visa' };
    } else {
      return { card_identification: '30', provider: 'visa' };
    }
  }

  if (amex.test(numberCreditCard.replace(/\s+/g, ""))) {
    //return { card_identification: 'AMEX', provider: 'amex' };if (cardType === 'Crédito') {
      return {card_identification: '90', provider: 'amex'};
    }  

console.log(cabal.test(numberCreditCard), "testst")
  if (cabal.test(numberCreditCard)) {
    if (cardType === 'Débito') {
      return {card_identification: '89', provider: 'cabal'};
    }
  }

  if (mastercard.test(numberCreditCard.replace(/\s+/g, "")) || mastercard2.test(numberCreditCard.replace(/\s+/g, ""))) {
    if (cardType === 'Crédito') {
      return { card_identification: '29', provider: 'mastercard' };
    } else {
      return { card_identification: '31', provider: 'mastercard' };
    }
  }

  if (maestro.test(numberCreditCard.replace(/\s+/g, ""))) {
    return { card_identification: '32', provider: 'maestro' };
  }

  return { card_identification: '-1', provider: 'otros' };
};
