import React from "react";
import { ButtonMGPrimary } from "./ButtonMGPrimary";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import { cleanPaymentState } from "../../store/slices";

export default function ButtonsStep({
  createUserAndPayment,
  nextStep,
  previousStep,
  formStep,
  invalidState,
}: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Stack direction={"row"}>
      {formStep === 0 && (
        <ButtonMGPrimary
          title="Salir"
          action={() => {
            dispatch(cleanPaymentState())
            navigate("/payment/list")
          }}
        />
      )}
      {formStep !== 0 && (
        <ButtonMGPrimary
          title="Volver"
          action={previousStep}
          disabled={formStep === 0}
        />
      )}
      {formStep !== 3 && (
        <ButtonMGPrimary
          foco={true}
          title="Siguiente"
          action={nextStep}
          disabled={invalidState}
        />
      )}
      {formStep === 3 && (
        <ButtonMGPrimary
          foco={true}
          title="Finalizar"
          action={createUserAndPayment}
          disabled={invalidState}
        />
      )}
    </Stack>
  );
}
