/**
 * Este js se encarga de la gestión del estado propio de las vistas signin y signup
 **/
import { createSlice } from "@reduxjs/toolkit";

/**
 * CreateSlice es un metodo que recibe un objeto de tres atributos
 * name - es el nombre de la objeto del store
 * initialState - es el estado con el que se inicia el objeto al levantar la applicacion
 * reducers - combina el action y el cambio de state de ese objeto cuando se usa
 */
export const variablesSlice = createSlice({
  name: "variables",
  initialState: {
    afip_types: [],
    alertCartExceded: false,
    alertAlcohol: true,
    document_types: [],
    gender_types: ["Masculino", "Femenino", "No binario", "Otro"],
    maxGroupStore: 3,
    searching: false,
    loading: false,
  },
  reducers: {
    setDocumentTypes: (state, action) => {
      state.document_types = action.payload;
    },
    setSearching: (state, action) => {
      state.searching = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAlertWeigthExceded: (state, action) => {
      state.alertCartExceded = action.payload;
    },
    setAlertAlcohol: (state, action) => {
      state.alertAlcohol = action.payload;
    },
  },
});

/**
 * Por un lado se exportan los actions asociados al reducer
 * por el otro se exporta el reducer en si mismo
 */
export const {
  setDocumentTypes,
  setSearching,
  setAlertWeigthExceded,
  setAlertAlcohol,
  setLoading,
} = variablesSlice.actions;
export default variablesSlice.reducer;
