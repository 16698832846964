const validations = {
  email: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
  password:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9])([^A-Za-z0-9]|[^ ]){8,15}$/,
  phone: /^([0-9]){10}$/,
  CUIT: /^([0-9]){11}$/,
  CUIL: /^([0-9]){11}$/,
  DNI: /^([0-9]){8}$/,
  Pasaporte: /^([0-9]){17}$/,
  Cédula: /^([0-9]){17}$/,
  numberDoc: /^([0-9]){8}$/,
};

export const validation = (reg, expression) => {
  let evaluate = new RegExp(validations[reg]);
  return evaluate.test(expression);
};
