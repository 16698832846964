import React from "react";
import "./styles.css";

const Card = ({ values, proveedor, step }: any) => {
  const {
    name = "xxxxx xxxxx",
    card_number = "XXXX",
    expirationDate = "XX/XX",
    security_code = "xxx",
  } = values;

  return (
    <div className="content_card">
      <div className={step === 2 ? "card card_hover" : "card"}>
        <div className="card__front card__part">
          <div style={{display:"flex", flexDirection: "row", justifyContent:"space-between"}}>
          <p style={{ marginLeft: 20 }}>{proveedor.toUpperCase() || "TARJETA"}</p>
          <p style={{ marginRight: 20 }}>{values.card_type}</p>
          </div>
          
          <p className="card_numer">
            **** **** **** {card_number.slice(-4) || "XXXX"}
          </p>

          <div className="card__space-75">
            <span className="card__label">Nombre del titular</span>
            <p className="card__info">{name}</p>
          </div>
          <div className="card__space-25">
            <span className="card__label">Vence</span>
          </div>
          <p className="card__info">{`${values.expirationDate.slice(0, 2)}/${values.expirationDate.slice(-2)}`}</p>
        </div>

        <div className="card__back card__part">
          <div className="card__black-line"></div>
          <div className="card__back-content">
            {name}
            <div className="card__secret">
              <p className="card__secret--last">{security_code || "***"}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
