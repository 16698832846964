import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";

import logo from "../assets/images/minigo_logo.png";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoading,
  setUserEmailForLogin,
  setUserUpdateSession,
} from "../store/actions";
import { validation, verifyUserExist } from "../utils";
import { LogoComponent, SpinnerLoader } from "../components";
import { LayoutBox, LayoutContainer } from "../components/CommonComponents";
import { ButtonMGPrimary } from "../components/buttons";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { newUser, emailDetected, userDummy, userLogged } = useSelector(
    (state: any) => state.session
  );

  const { loading } = useSelector((state: any) => state.variables);
  const [values, setValues] = React.useState({
    email: "",
    remember: false,
  });

  const handleChange = (prop: string) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value.toLowerCase() });
  };

  const signIn = async () => {
    await dispatch(setLoading(true));
    await dispatch(setUserUpdateSession(null));
    await verifyUserExist(values.email).then((res) => {
      dispatch(
        setUserEmailForLogin({ newUser: !res, emailDetected: values.email })
      );
      dispatch(setLoading(false));
    });
    await navigate("/password");
  };
  useEffect(() => {
    if (userLogged) {
      navigate("/select_card");
    } else if (emailDetected) {
      navigate("/password");
    }
  }, []);

  return loading ? (
    <div style={stl.content}>
      <SpinnerLoader />
    </div>

  ) : (
    <div style={stl.content}>
      <LayoutContainer>
        <LayoutBox>
          <LogoComponent image={logo} />
          <div style={{ display: 'flex', flex: 1, width: '100%', justifyContent: 'center' }}>
            <Typography alignSelf={'center'} component="h1" variant="h5">
              Bienvenidos a Minigo
            </Typography>
          </div>

          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              autoFocus
              margin="normal"
              required
              fullWidth
              id="email"
              label="Ingrese su email"
              name="email"
              value={values.email}
              autoComplete="email"
              onChange={handleChange("email")}
              error={!validation("email", values.email) && !!values.email}
            />

            <ButtonMGPrimary
              title="Continuar"
              action={signIn}
              disabled={!validation("email", values.email)}
            />
          </Box>
        </LayoutBox>
      </LayoutContainer>
    </div>
  );
}

const stl: any = {
  content: {
    display: "flex",
    flexDirection: "column",
    margin: 18,
    justifyContent: "space-evenly",
  },
  cardBtn: {
    textDecoration: "none",
    boxShadow: "none",
  },
};
