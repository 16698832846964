import { createSlice } from '@reduxjs/toolkit'
import { tokenAxios } from '../../utils/axios'

export interface LoginRequest {
    login: string
    password: string
}

type AuthState = {
    user: any | null
    newUser: boolean
    token: string | null
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        newUser: false,
        token: '',
    } as AuthState,
    reducers: {
        setCredentials: (state, { payload }) => {
            state.user = payload.user;
            state.newUser = payload.newUser;
            state.token = tokenAxios;
        },
        cleanUserSession: (state) => {
            state.user = null;
            state.newUser = false;
            state.token = '';
        },
    },
})

export const { setCredentials, cleanUserSession } = authSlice.actions
