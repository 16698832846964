import { AuthCore } from "../utils/axios";
import { loadAbort } from "../utils/load-abort-axios.utility";



export const getUserPaymentMethods = (login: string) => {
    const controller = loadAbort()
    return {
        call: AuthCore.post('/users/get_payment_cards', { login }, { signal: controller.signal }), controller
    }
};
