import React, { useEffect } from "react";
import { Box, TextField } from "@mui/material";

export const DocumentNumber = ({
  handleChange,
  values,
  setInvalidState,
}: any) => {

  useEffect(() => {
    setInvalidState(!(values.identificationNumber.length > 7));
  }, [values.identificationNumber, setInvalidState]);

  return (
    <Box display="contents" component="form" noValidate sx={{ m: 2 }}>
      <TextField
        autoComplete="off"
        autoCorrect="off"
        margin="normal"
        required
        fullWidth
        id="identificationNumber"
        label="Número de documento"
        name="identificationNumber"
        value={values.identificationNumber}
        onChange={handleChange("identificationNumber")}
        error={values.identificationNumber.length < 8}
      />
    </Box>
  );
};
