import React, { useEffect } from "react";
import { Box, TextField } from "@mui/material";
const validDataCardNumber = require('card-validator');

export const Owner = ({ handleChange, values, setInvalidState }: any) => {

  useEffect(() => {
    setInvalidState(!validDataCardNumber.cardholderName(values.name).isValid);
  }, [values.name]);

  return (
    <Box display="contents" component="form" noValidate sx={{ m: 5 }}>
      <TextField
        autoComplete="off"
        autoCorrect="off"
        autoFocus
        margin="normal"
        required
        fullWidth
        id="name"
        label="Nombre completo"
        name="name"
        value={values.completeCardName}
        onChange={handleChange("name")}
        error={!validDataCardNumber.cardholderName(values.name).isValid}
      />
    </Box>
  );
};
