import React from "react";
type LogoProp = {
  image: any;
};

export const LogoComponent = ({ image }: LogoProp) => {
  return (
    <div style={{ display: 'flex', flex: 1, width: '100%', justifyContent: 'center' }}>
      <img
        src={image}
        loading="lazy"
        style={{ maxWidth: 200, width: "40%", margin: 20, alignSelf: "center" }}
      />
    </div>
  );
};
