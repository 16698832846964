import { createSlice } from "@reduxjs/toolkit";

const init = {
  cartActive: false,
  productsInCart: [],
  summaryInCart: {},
  retryPayment: false,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartActive: false, // Cart created when go into store
    productsInCart: [], // Products added by user in store
    summaryInCart: {}, // Summary detail
    enterGroup: false, // entra solo o en grupo
    counterGroup: 0, // contador acompañantes
    groupComplete: false, // grupo lleno no puede escanear mas
  },
  reducers: {
    observerCartActive: (state, action) => {
      state.cartActive = action.payload;
    },
    setEnterGroup: (state, action) => {
      state.cartActive = action.payload;
    },
    incrementGroup: (state) => {
      state.counterGroup = state.counterGroup + 1;
    },
    completeGroup: (state, action) => {
      state.groupComplete = action.payload;
    },
    setProductsInCart: (state, action) => {
      state.productsInCart = action.payload;
    },
    setSummaryInCart: (state, action) => {
      state.summaryInCart = action.payload;
    },
    setRetryPayment: (state, action) => {
      state.retryPayment = action.payload;
    },
    actCartUpdate: (state, { payload }) => {
      state = { ...state, ...payload };
    },
    cleanCart: (state, action) => {
      state.cartActive = false;
      state.productsInCart = [];
      state.summaryInCart = {};
      state.retryPayment = false;
      state.enterGroup = false;
      state.counterGroup = 0;
      state.groupComplete = false;
    },
  },
});

/**
 * Por un lado se exportan los actions asociados al reducer
 * por el otro se exporta el reducer en si mismo
 */
export const {
  observerCartActive,
  setProductsInCart,
  setSummaryInCart,
  actCartUpdate,
  cleanCart,
  setRetryPayment,
  setEnterGroup,
  incrementGroup,
  completeGroup,
} = cartSlice.actions;
export default cartSlice.reducer;
