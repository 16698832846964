import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  LinearProgress,
  Button,
  Stack,
} from "@mui/material";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import WarningIcon from "@mui/icons-material/Warning";
import {
  getDefaultPaymentMethod,
  getOrderActive,
  getTransactionLast,
  postLeaveRetryOrder,
} from "../utils";
import { getAccessStatus } from "../services/public.service";
import { usePrice } from "../hooks/usePrice";
import { useNavigate } from "react-router-dom";
import { MessageModal } from "../components";
import { setRetryPayment } from "../store/slices";

export default function Cart() {
  let engineInterval: any;
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { userLogged } = useSelector((state: any) => state.session);
  const { productsInCart, summaryInCart, retryPayment } = useSelector(
    (state: any) => state.cart
  );

  const [summaryData, setSummaryData] = useState(null);
  const [apiMsg, setApiMsg] = useState(false);
  const [iconSelected, setIconSelected] = useState(() => WarningIcon);
  const [titleSelected, setTitleSelected] = useState("");
  const [descSelected, setDescSelected] = useState([]);
  const [actionsSelected, setActionsSelected] = useState([]);
  const [loading, setLoading] = useState(false);

  const [paymentCard, setPaymentCard] = useState();

  const messagesByCodeDoor = [
    {
      code: 0,
      icon: () => WarningIcon,
      title: "Error al procesar el pago",
      description: [
        "No se pudo procesar el cobro con tu medio de pago predeterminado.",
        "Si deseas realizar tu compra en otro momento, por favor devolvé los productos a sus respectivas góndolas.",
      ],
      actions: [
        {
          title: "Reintentar pago",
          colorPrimary: true,
          onClick: async () => {
            setApiMsg(false);
            await setLoading(true);
            await stopInterval();
            await retryPaymentMethod(userLogged.login);
            await startInterval();
            await setLoading(false);
          },
        },
        {
          title: "Cambiar medio de pago",
          colorPrimary: true,
          onClick: async () => {
            await dispatch(setRetryPayment(true));
            await stopInterval();
            await setApiMsg(false);
            await navigation("/select_card");
          },
        },
        {
          title: "Devolver productos",
          colorPrimary: false,
          onClick: async () => {
            await setLoading(true);
            await stopInterval();
            postLeaveRetryOrder({
              userId: userLogged.login,
              storeCode: 9,
              doorId: 9,
              code: 11,
              message: "Dejar productos para salir",
            });
            await startInterval();
            await setLoading(false);
            await setApiMsg(false);
          },
        },
      ],
    },
    {
      code: 14, // Tienda Cerrada
      icon: () => WarningIcon,
      title: "Tienda Cerrada",
      description: ["Tienda cerrada"],
      actions: [
        {
          title: "Entendido",
          colorPrimary: true,
          onClick: () => {
            stopInterval();
            setApiMsg(false);
            dispatch(setRetryPayment(false));
          },
        },
      ],
    },
    {
      code: 9, //
      icon: () => WarningIcon,
      title: "--",
      description: ["No found"],
      actions: [
        {
          title: "Entendido",
          colorPrimary: true,
          onClick: () => {
            stopInterval();
            setApiMsg(false);
          },
        },
      ],
    },
    {
      code: 100, //
      icon: () => WarningIcon,
      title: "Pago realizado",
      description: ["El pago fue procesado con éxito"],
      actions: [
        {
          title: "Ver resumen de compra",
          colorPrimary: true,
          onClick: async () => {
            await stopInterval();
            await setApiMsg(false);
            await dispatch(setRetryPayment(false));
            await navigation("/summary");
          },
        },
      ],
    },
    {
      code: 10, // Usuario confirmo y no ingresó a la tienda
      icon: () => WarningIcon,
      title: "Saliste de la tienda",
      description: ["Gracias por visitar MiniGO"],
      actions: [
        {
          title: "Entendido",
          colorPrimary: true,
          onClick: () => {
            stopInterval();
            setApiMsg(false);
            dispatch(setRetryPayment(false));
            navigation("/");
          },
        },
      ],
    },
    {
      code: 111, // Usuario confirmo y no ingresó a la tienda
      icon: () => WarningIcon,
      title: "Usted se ha retirado",
      description: ["Se ha retirado sin productos en la tienda."],
      actions: [
        {
          title: "Entendido",
          colorPrimary: true,
          onClick: () => {
            stopInterval();
            setApiMsg(false);
            dispatch(setRetryPayment(false));
            navigation("/");
          },
        },
      ],
    },
    {
      code: 12, // multiples grupos en salida
      icon: () => WarningIcon,
      title: "Volver a la tienda",
      description: [
        "Su grupo debe volver a la tienda.",
        "Solo puede salir un grupo a la vez.",
      ],
      actions: [
        {
          title: "Entendido",
          colorPrimary: true,
          onClick: () => {
            setApiMsg(false);
          },
        },
      ],
    },
    {
      code: 13, // grupo incompleto
      icon: () => WarningIcon,
      title: "Grupo incompleto",
      description: [
        "Todos los integrantes de tu grupo deben estar en el hall para avanzar con el pago o salir de la tienda.",
      ],
      actions: [
        {
          title: "Entendido",
          colorPrimary: true,
          onClick: () => {
            setApiMsg(false);
          },
        },
      ],
    },
  ];

  useEffect(() => {
    if (!apiMsg) {
      cleanMsgCode();
    }
    return () => { };
  }, [apiMsg]);

  // Evaluo permisos al entrar, al salir limpio el interval
  useEffect(() => {
    getDefaultMethod();
    const cartActive: any = evaluationCart();
    if (cartActive) {
      startInterval();
    }
    return () => stopInterval();
  }, []);

  const getDefaultMethod = async () => {
    await getDefaultPaymentMethod(userLogged.login).then((res: any) => {
      setPaymentCard(res);
    });
  };

  // Evaluacion principal que activa el get de productos
  const evaluationCart = async () => {
    const res = await getOrderActive(userLogged.login, dispatch);
    if (!res.length) {
      navigation("/");
    }
    return res;
  };

  const startInterval = () => {
    engineInterval = setInterval(() => {
      getOrderActive(userLogged.login, dispatch);
      accessStatus();
    }, 10000);
  };

  const stopInterval = () => {
    clearInterval(engineInterval);
  };

  // FUNCION QUE PIDE MENSAJES CONSTANTEMENTE SOBRE ESTATUS DE LA PUERTA Y PAGO
  const accessStatus = async () => {
    try {
      const response = await getAccessStatus(userLogged.login, 9);

      if (response.result.length > 0) {
        await stopInterval();

        const accessStoreResponse = response.result[response.result.length - 1];
        const codeResponse = parseInt(accessStoreResponse.code);

        if (codeResponse === 0) {
          await dispatch(setRetryPayment(true));
        }
        await configMsgByCode(codeResponse);
      }
    } catch (error) { }
  };

  // Funcion para estructurar modal de notificacion y que accione el usuario
  // abrir flujo acompañantes
  const configMsgByCode = (codeResponse: any) => {
    const elementCode: any = messagesByCodeDoor.find((el) => {
      return el.code === parseInt(codeResponse);
    });

    setIconSelected(elementCode.icon);
    setTitleSelected(elementCode.title);
    setDescSelected(elementCode.description);
    setActionsSelected(elementCode.actions);
    setApiMsg(true);
  };

  const cleanMsgCode = () => {
    setIconSelected(() => WarningIcon);
    setTitleSelected("");
    setDescSelected([]);
    setActionsSelected([]);
    setApiMsg(false);
  };

  const retryPaymentMethod = async (login: string) => {
    try {
      await postLeaveRetryOrder({
        userId: login,
        storeCode: 9,
        doorId: 4,
        code: 9,
        message: "Retry order payment",
      });
      await getTransactionLast(userLogged.login).then((res: any) => {
        configMsgByCode(res.code);
      });
    } catch (error) {
      console.error(error);
    }
  };

  return apiMsg ? (
    <div style={stl.contentFit}>
      <MessageModal
        type="text"
        Icon={iconSelected}
        title={titleSelected}
        text={descSelected}
        actions={actionsSelected}
        handleClose={() => setApiMsg(false)}
        open={apiMsg}
      />
    </div>
  ) : !productsInCart.length ? (
    <div style={stl.content}>
      <Stack spacing={3} style={{ alignItems: "center" }}>
        <ShoppingCartRoundedIcon
          color="primary"
          style={{ fontSize: "128px" }}
        />
        <Typography
          variant="h4"
          color="white"
          align="center"
          style={{ fontWeight: "bold" }}
        >
          Tu carrito está vacío
        </Typography>
        <Typography variant="h6" color="white" align="center">
          Comienza tu compra agarrando un producto
        </Typography>
        <Button style={{ margin: "20px" }} onClick={() => navigation("/")}>
          VOLVER
        </Button>
      </Stack>
      {apiMsg && (
        <div style={stl.contentFit}>
          <MessageModal
            type="text"
            Icon={iconSelected}
            title={titleSelected}
            text={descSelected}
            actions={actionsSelected}
            handleClose={() => setApiMsg(false)}
            open={apiMsg}
          />
        </div>
      )}
    </div>
  ) : (
    <div style={stl.contentFit}>
         <Typography
        variant="h5"
        color="white"
        style={{ fontWeight: "bold", fontSize: "20px" , margin: "5% 0 6% 0"}}
      >
       TU PEDIDO
      </Typography>
      <List style={stl.contentMain}>
        {productsInCart?.map((el: any) => (
          <ItemProduct key={el.barcode} item={el} />
        ))}
      </List>
      <LinearProgress variant="determinate" value={100} />
      <SummaryTotals
        summary={summaryInCart}
        paymentCard={paymentCard}
        navigation={navigation}
        login={userLogged.login}
        retryPayment={retryPayment}
        retryPaymentMethod={retryPaymentMethod}
      />
    </div>
  );
}

const SummaryTotals = ({
  summary,
  paymentCard,
  navigation,
  login,
  retryPayment,
  retryPaymentMethod,
}: {
  summary: any;
  paymentCard: any;
  navigation: any;
  login: any;
  retryPayment: any;
  retryPaymentMethod: any;
}) => {
  const { amount_total, amount_undiscounted } = summary;

  return (
    <div style={stl.contentSummary}>
      <Typography
        variant="h5"
        color="white"
        style={{ fontWeight: "bold", fontSize: "20px" , marginTop:10}}
      >
        Total:
      </Typography>
      <Typography variant="h4" color="white" style={{ fontWeight: "bold" }}>
        {usePrice(amount_total)}
      </Typography>
      <Typography variant="body1" color="white">
        {paymentCard &&
          `Debitaremos de tu tarjeta terminada en *${paymentCard[2].substr(
            paymentCard[2].length - 3
          )}`}
      </Typography>
      <Button
        onClick={() => navigation("/select_card")}
        variant="text"
        color="primary"
      >
        Cambiar medio de pago
      </Button>
      {/* <Button variant="text" color="primary">
          Solicitar Asistencia
        </Button> */}

      {retryPayment && (
        <>
          <Button
            title="REINTENTAR PAGO"
            style={{ margin: "20px" }}
            onClick={() => retryPaymentMethod(login)}
          >
            REINTENTAR PAGO
          </Button>

          <Button
            title="DEVOLVER PRODUCTOS"
            style={{ margin: "20px" }}
            onClick={() =>
              postLeaveRetryOrder({
                userId: login,
                storeCode: 9,
                doorId: 9,
                code: 11,
                message: "Retry order payment",
              })
            }
          >
            DEVOLVER PRODUCTOS
          </Button>
        </>
      )}
    </div>
  );
};

export const ItemProduct = ({ item }: { item: any }) => {
  const {
    name,
    image_link,
    product_name,
    price_unit,
    price_total,
    discount,
    product_uom_qty,
  } = item;

  return (
    <ListItem dense style={{ padding: "2px" }} alignItems="flex-start">
      <ListItemAvatar style={{ marginRight: 10 }}>
        <Avatar
          variant="rounded"
          alt={product_name}
          style={{ height: 80, width: 80 }}
          src={image_link}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography
            sx={{ display: "inline", fontWeight: "bold", fontSize: 16 }}
            component="span"
            variant="body2"
            color="primary"
          >
            {name}
          </Typography>
        }
        secondary={
          <React.Fragment>
            <Typography
              sx={{ display: "inline", fontSize: 12 }}
              variant="body2"
              color="text.primary"
            >
              {product_name}
            </Typography>

            <Typography variant="body2" style={{ display: "inline-flex" }}>
              Precio unitario: {usePrice(price_unit)}
              {discount ? <div style={stl.discount}>-{discount}%</div> : <></>}
            </Typography>
            <Typography variant="body2">Unidades: {product_uom_qty}</Typography>
          </React.Fragment>
        }
      />
      <Typography
        style={{
          position: "absolute",
          color: "white",
          fontSize: "24px",
          bottom: 0,
          right: 10,
        }}
      >
        {usePrice(price_total)}
      </Typography>
    </ListItem>
  );
};

const stl: any = {
  contentFit: {
    height: "100vh",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    height: "100vh",
  },
  containerProducts: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  contentMain: {
    width: "100%",
    bgcolor: "background.paper",
    maxHeight: "80vh",
    minHeight:"72vh",
    overflowY: "auto",
  },
  discount: {
    backgroundColor: "red",
    color: "white",
    marginLeft: "0.5em",
  },
  contentSummary: {
    textAlign: "center",
   bottom: 0,
  },
};
