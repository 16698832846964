import { Stack, Typography } from '@mui/material'
import React from 'react'
import './styles.css'

export const Loader = () => {

  return (
    <div className="loader">
        <Stack spacing={3}>
            <Typography variant="h5" color="white" align="center">
                Aguardá un momento
            </Typography>
            <Typography variant="h6" color="white" align="center">
                En breve podrás entrar a la tienda
            </Typography>
        </Stack>
        <Stack spacing={3}>
            <span>Cargando...</span>
        </Stack>
    </div>
  )
}


