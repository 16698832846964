// WEB
const POST_LOGIN = '/users/login';
const POST_USER_PAYMENTS = '/users/get_payment_cards';
const POST_USER_NEW_PAYMENT = '/users/payment_cards';
const DELETE_USER_PAYMENT = '/users/payment_cards_delete';//'/users/payment_cards';
const GET_USER_EXIST = '/users?'; //login=${login}&vat=${vat}&identification_type=${identification_type}
const PATCH_USER_NEW_PAYMENT = '/users/payment_cards_patch'; //payment_cards';
const DELETE_USER_ADDRESS = '/online_shopping_app/user/del_child_contact';
const POST_ENTER_STORE = '/users/EnterStore';



// Constantsyar
const GET_DOC_TYPES = 'users/DocumentTypes';
const GET_AFIP_TYPES = 'users/afip_responsibility_types';
const GET_PAYMENTS_CARDS_TYPES = 'users/payment_cards_types';
const GET_STORES = '/store/get_list';
const GET_CATEGORIES =
  'online_shopping_app/products/get_product_public_category';
const GET_PROMOS_BANNER = 'online_shopping_app/get_deals';

// User Session
const POST_REGISTER_ODOO = '/users';
const DELETE_USER = '/users';
const PUT_USER_DATA = '/users';
const PUT_USER_AVATAR = '/users/avatar';
const POST_RECOVER_PASS = '/users/ResetPassword';

// User Data
const GET_USER_ADDRESS_CHILDS = '/online_shopping_app/user/get_childs_contacts'; //login=${login}
const POST_USER_NEW_ADDRESS = '/online_shopping_app/user/add_childs_contacts';
const GET_USER_LAST_ORDER = '/sale_order_list'; // {  params: {  login: user  }}
const GET_USER_CURRENT_ORDER = '/sale_order_cart?'; //login=${user}`);
const GET_ORDER_DETAIL = '/sale_order_detail?'; //sale_order=${orderID}`,

// SCANNER AND STORE STATUS

const POST_TRANSACTIONS_GET = '/users/get_transaction';
const POST_ACCESS_DATA = 'users/send_message_access_control';
const POST_LEAVE_OR_RETRY_PAYMENT = '/get_message_access_control';
const GET_SEARCH_PRODUCTS = 'online_shopping_app/product/name?';
const GET_PRODUCTS_BY_SUBCATEGORY =
  'online_shopping_app/products/get_products_by_category?'; //store_id=${storeId}&categ_id=${code}`,
const GET_PRODUCTS_BY_CODE =
  'online_shopping_app/products/get_product_details?'; // default_code=${code}`,
const POST_CREATE_CART = 'online_shopping_app/sale_create';
const POST_CANCEL_ORDER_PENDING = 'online_shopping_app/sale_cancel';

// Orders
const GET_ORDER_BY_PAGE = 'sale_order_list?'; //login=${user}&order_for_page=100`

// Manage app
const POST_CREATE_TICKET = 'helpdesk/ticket';

export {
  POST_USER_PAYMENTS,
  GET_SEARCH_PRODUCTS,
  GET_PRODUCTS_BY_SUBCATEGORY,
  GET_PRODUCTS_BY_CODE,
  POST_CREATE_CART,
  POST_CANCEL_ORDER_PENDING,
  GET_PROMOS_BANNER,
  GET_CATEGORIES,
  GET_USER_CURRENT_ORDER,
  GET_ORDER_DETAIL,
  POST_LEAVE_OR_RETRY_PAYMENT,
  GET_STORES,
  GET_DOC_TYPES,
  GET_AFIP_TYPES,
  GET_PAYMENTS_CARDS_TYPES,
  POST_LOGIN,
  POST_REGISTER_ODOO,
  GET_USER_EXIST,
  GET_USER_ADDRESS_CHILDS,
  DELETE_USER,
  PUT_USER_DATA,
  PUT_USER_AVATAR,
  POST_RECOVER_PASS,
  DELETE_USER_ADDRESS,
  POST_USER_NEW_ADDRESS,
  POST_CREATE_TICKET,
  GET_ORDER_BY_PAGE,
  POST_ENTER_STORE,
  POST_TRANSACTIONS_GET,
  POST_ACCESS_DATA,

  POST_USER_NEW_PAYMENT,
  DELETE_USER_PAYMENT,
  PATCH_USER_NEW_PAYMENT,
  GET_USER_LAST_ORDER,
};
