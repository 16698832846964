import React, { useEffect, useState } from "react";
import { Stack, TextField } from "@mui/material";
import { creditCardType } from "../../utils/validateCard";
const validDataCardNumber = require('card-validator');

export const Expiration = ({ handleChange, values, setInvalidState }: any) => {
  const[prov, setProv]= useState(false)

  useEffect(() => {

    const { provider } = creditCardType(values.card_number.replace(/\s+/g, ""), values.card_type,);
    provider === "amex"?  setProv(true): setProv(false)
    const validateCVV = prov? validDataCardNumber.cvv(values.security_code, 4).isValid: validDataCardNumber.cvv(values.security_code, 3).isValid

    setInvalidState(!(
      validDataCardNumber.expirationMonth(values.expirationDate.slice(0, 2))
        .isValid &&
      validDataCardNumber.expirationYear(values.expirationDate.slice(-2))
        .isValid &&
        validateCVV 
    ));

  }, [values]);

  const expriy_format = (value: any) => {
    const expdate = value;
    const expDateFormatter =
      expdate.replace(/\//g, "").substring(0, 2) +
      (expdate.length > 2 ? "/" : "") +
      expdate.replace(/\//g, "").substring(2, 4);

    return expDateFormatter;
  };

  return (
    <Stack alignItems="center" width={"95%"}>
      <TextField
        autoComplete="off"
        autoCorrect="off"
        autoFocus
        required
        fullWidth
        margin="normal"
        id="expirationDate"
        label="Vencimiento"
        name="expirationDate"
        value={expriy_format(values.expirationDate)}
        onChange={handleChange("expirationDate")}
        error={!(
          validDataCardNumber.expirationMonth(values.expirationDate.slice(0, 2))
            .isValid &&
          validDataCardNumber.expirationYear(values.expirationDate.slice(-2))
            .isValid &&
          values.expirationDate.length === 4
        )}
        inputProps={{ inputMode: "numeric", pattern: "[0-9/]*", maxLength: 5 }}
      />
 
      <TextField
        autoComplete="off"
        autoCorrect="off"
        required
        fullWidth
        id="security_code"
        label="Código Seguridad"
        name="security_code"
        value={values.security_code}
        onChange={handleChange("security_code")}
        error={prov? !validDataCardNumber.cvv(values.security_code, 4).isValid:  !validDataCardNumber.cvv(values.security_code, 3).isValid}
        inputProps={{ inputMode: "numeric", pattern: "[0-9]*", maxLength:prov? 4:3 }}
        type="password"
      />
    </Stack>
  );
};
