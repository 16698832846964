import axios from "axios";
import { ApiCore, AuthCore, API_URL, tokenAxios } from "./axios";

import {
  GET_ORDER_BY_PAGE,
  DELETE_USER_PAYMENT,
  GET_DOC_TYPES,
  POST_USER_NEW_PAYMENT,
  PATCH_USER_NEW_PAYMENT,
} from "./endpoints";

const BASE_URL = API_URL;

const getDocumentTypes = async () => {
  const res = await rest("GET", GET_DOC_TYPES);
  return res;
};

// TOKEN
const setDefaultPaymentCard = async (card) => {
  const response = await AuthCore.patch(PATCH_USER_NEW_PAYMENT, {
    ...card,
  }).catch(() => {
    return false;
  });
  return response;
};

// TOKEN
const createPaymentCard = async (method) => {
  const response = await AuthCore.post(POST_USER_NEW_PAYMENT, {
    ...method,
  }).catch(() => {
    return false;
  });

  if (response?.data?.result?.status === "201") {
    return true;
  } else {
    return false;
  }
};

const deletePaymentMethod = async (login, id) => {
  await AuthCore.delete(DELETE_USER_PAYMENT, {
    data: { login, id },
    headers: { Authorization: tokenAxios },
  });
};

const getUserOrdersByPage = async (login) => {
  const response = await rest("GET", GET_ORDER_BY_PAGE, {
    params: { login },
  });
  return response;
};

const rest = async (method = "GET", urlPetition, data) => {
  let response = "";

  // Ejecuta axios segun el metodo que le llegue
  try {
    switch (method) {
      case "POST":
        response = await ApiCore.post(urlPetition, data);
        break;

      case "DELETE":
        response = await axios.delete(`${BASE_URL}${urlPetition}`, {
          data,
        });
        break;

      case "PATCH":
        response = await axios.patch(`${BASE_URL}${urlPetition}`, data, {
          headers: {
            "Content-Type": "application/json",
          },
          data: {},
        });
        break;

      default:
        response = await ApiCore.get(urlPetition, data);
        break;
    }

    return response.data;
  } catch (error) {
    console.error("rompe: ", method, urlPetition, data);
    return { error: `Error Catch ${method} - ${urlPetition} - data: ${data}` };
  }
};

export {
  getDocumentTypes,
  createPaymentCard,
  setDefaultPaymentCard,
  getUserOrdersByPage,
  deletePaymentMethod,
  rest,
};
